import React from "react";
import logo from "./../pics/dual_imports_logo.png";
import cachamai_logo from "./../pics/cachamai_logo.jpg";
import vitanna_logo from "./../pics/vitanna_logo.jpg";

export const Four = () => {
  return (
    <div>
      <div className="construction">
        <div className="logoback">
          <img className="logo" src={logo} alt=""></img>
        </div>
        <div className="div1">
          <text>Dual Imports © Quality Foods Importers and Distributors</text>
          <text>
            Dual Imports © Importateurs et distributeurs d'aliments de qualité
          </text>
        </div>
        <text>Website under construction...</text>
        <text>Site web en construction...</text>
        <div className="products">
          <div className="text_2">
            <text>View our main products below:</text>
            <text>Visitez nos produits principaux ci-dessous:</text>
            <div className="images">
              <a href="https://cachamai.com.ar/en/">
                {" "}
                <img className="image" src={cachamai_logo} alt=""></img>
              </a>
              <a href="https://vitanna.com.uy/en/">
                {" "}
                <img className="image" src={vitanna_logo} alt=""></img>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        {" "}
        <text className="textfooter">
          Dual Imports © Quality Foods Importers and Distributors
        </text>
        <text className="textfooter">
          Dual Imports © Importateurs et distributeurs d'aliments de qualité
        </text>
      </div>
    </div>
  );
};
